/*  Layout Styles -----------------------------------------*/

#layout{
	overflow: hidden;
	position: relative;
	-webkit-transition: 0.5s all ease;
	-moz-transition: 0.5s all ease;
	-o-transition: 0.5s all ease;
	-ms-transition: 0.5s all ease;
	transition: 0.5s all ease;
}
.layout-wide{
	max-width: 100%;
	margin: auto;
	box-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-webkit-transition: 0.5s all ease;
	-moz-transition: 0.5s all ease;
	-o-transition: 0.5s all ease;
	-ms-transition: 0.5s all ease;
	transition: 0.5s all ease;
}
.layout-boxed{
	max-width: 1230px;
	margin: 0 auto;
	-webkit-box-shadow: 0 1px 10px rgba(0,0,0,0.3);
	-moz-box-shadow: 0 1px 10px rgba(0,0,0,0.3);
	box-shadow: 0 1px 10px rgba(0,0,0,0.3);
	-webkit-transition: 0.5s all ease;
	-moz-transition: 0.5s all ease;
	-o-transition: 0.5s all ease;
	-ms-transition: 0.5s all ease;
	transition: 0.5s all ease;
}
.layout-boxed-margin{
	max-width: 1230px;
	margin: 50px auto;
	-webkit-box-shadow: 0 1px 10px rgba(0,0,0,0.3);
	-moz-box-shadow: 0 1px 10px rgba(0,0,0,0.3);
	box-shadow: 0 1px 10px rgba(0,0,0,0.3);
	-webkit-transition: 0.5s all ease;
	-moz-transition: 0.5s all ease;
	-o-transition: 0.5s all ease;
	-ms-transition: 0.5s all ease;
	transition: 0.5s all ease;
}
.layout-boxed header, 
.layout-boxed-margin header{
	position: relative;
}
.layout-boxed #slide-services .container, 
.layout-boxed-margin #slide-services .container,
.layout-boxed #slide-team .container, 
.layout-boxed-margin #slide-team .container{
	float: left;
	margin-left: 30px;
}

/* COLOR CHANGER -------------------------------*/

.colorbox { width:100%; height:100%; cursor:pointer; display:block; }
.red { background-color:#e64b3c; }
.blue { background-color:#29c0d4; }
.yellow { background-color:#FFB43F; }
.green { background-color:#88C425; }
.orange { background-color:#FF5E00; }
.purple { background-color:#894997; }
.pink { background-color:#FF3D7F; }
.cocoa { background-color:#755C3B; }
.suelte { background-color:#7FC7AF; }
.grey { background-color:#8899a6; }

/* BACKGROUNDS PATTERS -------------------------------*/

.bgnone { background-image: url("../../img/bg-theme/none.jpg"); }
.bg1 { background-image: url("../../img/bg-theme/1.png"); }
.bg2 { background-image: url("../../img/bg-theme/2.png"); }
.bg3 { background-image: url("../../img/bg-theme/3.png"); }
.bg4 { background-image: url("../../img/bg-theme/4.png"); }
.bg5 { background-image: url("../../img/bg-theme/5.png"); }
.bg6 { background-image: url("../../img/bg-theme/6.png"); }
.bg7 { background-image: url("../../img/bg-theme/7.png"); }
.bg8 { background-image: url("../../img/bg-theme/8.png"); }
.bg9 { background-image: url("../../img/bg-theme/9.png"); }
.bg10 { background-image: url("../../img/bg-theme/10.png"); }
.bg11 { background-image: url("../../img/bg-theme/11.png"); }
.bg12 { background-image: url("../../img/bg-theme/12.png"); }
.bg13 { background-image: url("../../img/bg-theme/13.png"); }
.bg14 { background-image: url("../../img/bg-theme/14.png"); }
.bg15 { background-image: url("../../img/bg-theme/15.png"); }
.bg16 { background-image: url("../../img/bg-theme/16.png"); }
.bg17 { background-image: url("../../img/bg-theme/17.png"); }
.bg18 { background-image: url("../../img/bg-theme/18.png"); }
.bg19 { background-image: url("../../img/bg-theme/19.png"); }
.bg20 { background-image: url("../../img/bg-theme/20.png"); }
.bg21 { background-image: url("../../img/bg-theme/21.png"); }
.bg22 { background-image: url("../../img/bg-theme/22.png"); }
.bg23 { background-image: url("../../img/bg-theme/23.png"); }
.bg24 { background-image: url("../../img/bg-theme/24.png"); }
.bg25 { background-image: url("../../img/bg-theme/25.png"); }
.bg26 { background-image: url("../../img/bg-theme/26.png"); }

/* BACKGROUNDS PATTERS -------------------------------*/

#theme-options ul.backgrounds li.bgnone { background-image: url("../../img/bg-theme/none.jpg"); }
#theme-options ul.backgrounds li.bg1 { background-image: url("../../img/bg-theme/1.png"); }
#theme-options ul.backgrounds li.bg2 { background-image: url("../../img/bg-theme/2.png"); }
#theme-options ul.backgrounds li.bg3 { background-image: url("../../img/bg-theme/3.png"); }
#theme-options ul.backgrounds li.bg4 { background-image: url("../../img/bg-theme/4.png"); }
#theme-options ul.backgrounds li.bg5 { background-image: url("../../img/bg-theme/5.png"); }
#theme-options ul.backgrounds li.bg6 { background-image: url("../../img/bg-theme/6.png"); }
#theme-options ul.backgrounds li.bg7 { background-image: url("../../img/bg-theme/7.png"); }
#theme-options ul.backgrounds li.bg8 { background-image: url("../../img/bg-theme/8.png"); }
#theme-options ul.backgrounds li.bg9 { background-image: url("../../img/bg-theme/9.png"); }
#theme-options ul.backgrounds li.bg10 { background-image: url("../../img/bg-theme/10.png"); }
#theme-options ul.backgrounds li.bg11 { background-image: url("../../img/bg-theme/11.png"); }
#theme-options ul.backgrounds li.bg12 { background-image: url("../../img/bg-theme/12.png"); }
#theme-options ul.backgrounds li.bg13 { background-image: url("../../img/bg-theme/13.png"); }
#theme-options ul.backgrounds li.bg14 { background-image: url("../../img/bg-theme/14.png"); }
#theme-options ul.backgrounds li.bg15 { background-image: url("../../img/bg-theme/15.png"); }
#theme-options ul.backgrounds li.bg16 { background-image: url("../../img/bg-theme/16.png"); }
#theme-options ul.backgrounds li.bg17 { background-image: url("../../img/bg-theme/17.png"); }
#theme-options ul.backgrounds li.bg18 { background-image: url("../../img/bg-theme/18.png"); }
#theme-options ul.backgrounds li.bg19 { background-image: url("../../img/bg-theme/19.png"); }
#theme-options ul.backgrounds li.bg20 { background-image: url("../../img/bg-theme/20.png"); }
#theme-options ul.backgrounds li.bg21 { background-image: url("../../img/bg-theme/21.png"); }
#theme-options ul.backgrounds li.bg22 { background-image: url("../../img/bg-theme/22.png"); }
#theme-options ul.backgrounds li.bg23 { background-image: url("../../img/bg-theme/23.png"); }
#theme-options ul.backgrounds li.bg24 { background-image: url("../../img/bg-theme/24.png"); }
#theme-options ul.backgrounds li.bg25 { background-image: url("../../img/bg-theme/25.png"); }
#theme-options ul.backgrounds li.bg26 { background-image: url("../../img/bg-theme/26.png"); }


/* Theme Options ----------------------------------------------*/

#theme-options {	
	position:fixed;	
	z-index: 9999;	
	width: auto;	
	left:-222px;
	width: 222px;
	top: 100px;
	border: 1px solid #dbdbdb;
	border-left: 0;
	border-radius: 0 8px 8px 0;
	background: #fff;
	z-index: 999999;	
	padding-bottom: 10px;
	-webkit-transition: 0.3s all ease;
	-moz-transition: 0.3s all ease;
	-o-transition: 0.3s all ease;
	-ms-transition: 0.3s all ease;
	transition: 0.3s all ease;
}
#theme-options .title {
	padding: 10px 5px 0 12px;	
	font-size: 11px;
	line-height: 18px;
	font-weight: normal;
	position: relative;
}
#theme-options .title p{
	margin-bottom: 5px;
	font-size: 0.7rem;
	line-height: 1.2rem;
}
#theme-options .title span{
	margin-left: -12px;
}
#theme-options .openclose {
	cursor: pointer;
	display: block;
	height: 301px;
	position: absolute;
	top: 20px;
	right: -39px;
	width: 40px;
	background: transparent url("../../img/img-theme/theme-options.png") no-repeat top left;
}
#theme-options span {
	display: block;
	width: 100%;
	padding: 0 12px 0 12px;
	font-size: 14px;
}
#theme-options ul { 
	padding: 6px 12px; 
	margin: 0;
}
#theme-options ul li {
	display: inline-block;
	margin: 0;
	border: 1px solid #dbdbdb;
	overflow: hidden;
	width: 15px;
	height: 15px;
	background-repeat: repeat;
	cursor: pointer;
}
#theme-options ul.layout-style li.full:last-child{
	width: 98%;
}
#theme-options ul.backgrounds li:hover { 
	border-color: #ccc; 
}
#theme-options ul.backgrounds li.active,#theme-options ul.backgrounds li.active:hover { 
	border-color: #ff9600; 
}
#theme-options .layout-style li{
	width: 48%;
	padding:5px 3px;	
	display: inline-block;
	height: auto;
	font-size: 11px;
	line-height: 20px;
	text-align: center;
}
#theme-options .layout-style li.active,
#theme-options .layout-style li.active a{
	color: #fff;
}
#theme-options .layout-style li  a:hover{
	text-decoration: none;
}
#theme-options .layout-style li a{
	font-size: 11px;
	color: #424242;
}
.patterns{
	display: none;
}