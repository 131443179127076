/*
* 	Owl Carousel Owl Demo Theme 
*	v1.22
*/

.owl-theme .owl-controls{
	margin-top: 0;
	text-align: center;
}

/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div{
	color: #FFF;
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
	margin: 5px;
	padding: 0 12px;
	font-size: 12px;;
	background: #131313;
	opacity: 0.9;
	color: #fff;
}
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover{
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
	text-decoration: none;
}

/* Styling Pagination*/

.owl-theme .owl-controls .owl-page{
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
}
.owl-theme .owl-controls .owl-page span{ /* es este pilas*/
	display: block;
	width: 12px;
	height: 12px;
	margin: 5px 7px;
	filter: Alpha(Opacity=50);
	opacity: 0.5;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	background: #869791;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
}

/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers{
	height: auto;
	width: auto;
	color: #FFF;
	padding: 2px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}

/* preloading images */
.owl-item.loading{
	min-height: 190px;
	background: url(AjaxLoader.gif) no-repeat center center
}


/* Styling Next and Prev buttons  Titles -----------------------------*/

.services .owl-carousel .owl-wrapper-outer{
	height: 250px;
	margin-top: -52px;
}
.services .owl-item{
	margin-top: 50px;
}
#services  .owl-prev,
#services .owl-next{	
	position: absolute;
	top: -50px;
}
#services .owl-prev{
	left: 28%;
}
#services  .owl-next{
	right:28%;
}

/* Styling Next and Prev buttons  animation-services -----------------------------*/

#slide-services  .owl-prev,
#slide-services .owl-next,
#works  .owl-prev,
#works  .owl-next,
#slide-team  .owl-prev,
#slide-team  .owl-next,
#services-carousel  .owl-prev,
#services-carousel  .owl-next{	
	position: absolute;
	top: 36%;
	width: 80px;
	height: 80px;
	border-radius: 40px;
	z-index: 10;
	cursor: pointer;
	font-size: 16px;
	font-weight: bold;	
}
#slide-services  .owl-prev i,
#slide-services .owl-next i,
#works  .owl-prev i,
#works  .owl-next i,
#slide-team .owl-prev i,
#slide-team .owl-next i,
#services-carousel  .owl-prev i,
#services-carousel  .owl-next i{
	font-size: 18px;
	font-weight: bold;	
}
#slide-services .owl-prev,
#works  .owl-prev,
#slide-team .owl-prev,
#services-carousel  .owl-prev{
	left: -60px;	
	text-align: right;
	padding-top: 28px;
}
#slide-services  .owl-next,
#works  .owl-next,
#slide-team .owl-next,
#services-carousel  .owl-next{
	right: -55px;
	text-align: left;
	padding-top: 28px;
}
#slide-services .owl-pagination,
#works  .owl-pagination,
#slide-team .owl-pagination{
	background-color: #fff;
	display: inline;
	padding: 10px 20px 0 20px;
	border-radius: 5px;
	border: 1px solid #dedede;
}
#slide-services .owl-controls,
#works  .owl-controls{
	margin-top: 50px;
	text-align: center;
}
#slide-team  .owl-controls{
	margin: 0;
}

/* Styling  Services Carousel -----------------------------*/

.services-carousel .owl-carousel .owl-wrapper-outer{
	height: 250px;	
	margin: 0 auto;
	margin-top: -52px;
}
#services-carousel{
	padding: 0 50px;
}
.services-carousel .owl-item{
	margin-top: 50px;
}

/* Styling Next and Prev buttons Sponsors -----------------------------*/

#sponsors  .owl-prev,
#sponsors .owl-next{	
	position: absolute;
	bottom: -70px;
	color: #fff;
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
	margin: 5px;
	padding: 0 12px;
	font-size: 12px;;

	opacity: 0.9;
}
#sponsors .owl-prev{
	left: 45%;
}
#sponsors  .owl-next{
	right: 45%;
}
